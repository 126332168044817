<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second paket"
    style="font-family: Montserrat"
  >
    <!-- <div class="alert alert-primary mt-2" role="alert" @click="cara()" style="border: none !important;border-radius: 0px;margin-bottom:-12px !important;">
      <i class="fa fa-info-circle pr-2"></i><span style="font-size:12px;">Panduan <b>(Klik Disini)</b></span>
    </div> -->
    <v-dialog/>
    <alert-error ref="ae"/>
    <date-picker v-model="startDate" valueType="format"></date-picker>
    <div class="container mt-1" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="1" />
        <content-placeholders-img />
        <content-placeholders-text :lines="2" />
        <content-placeholders-text :lines="1" />
      </content-placeholders>
    </div>
    <div v-else>
      <!--     
    <div class="container" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-img /> 
      </content-placeholders> 
      </div>-->
      
      <div class="table-responsive text-center" style="font-size: 12px;font-family:roboto">
        <table class="table table-borderless table-striped table-hover mt-3">
          <thead>
            <tr>
              <th scope="col">Tanggal</th>
              <th scope="col">Dari Jam</th>
              <th scope="col">Sampai Jam</th>
              <th scope="col">Keterangan</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td class="no_resi">02/04/2021</td>
              <td class="pengirim">16.00</td>
              <td class="pengirim">18.00</td>
              <td class="penerima">Patroli tambahan 2 jam</td> 
              <td class="status">
                <label class="badge mb-0 badge-success">Disetujui</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="container" v-else>
        <div class="text-date d-flex bg-highlight justify-content-center bg-light rounded p-3"> Belum ada pengajuan cuti ditanggal ini</div>
      </div>  -->
      <!-- Modal  -->
      <modal v-show="isModalVisible" @close="closeModal" style="z-index: 99">
        <!-- Override Modal Header -->
        <template v-slot:header><b style="color: #2b80ac">Update Tamu</b></template>
        <!-- Override Modal body -->
        <template v-slot:body>
          <form id="edit-form" class="form-horizontal" @submit.prevent="update(dataModal.id)" enctype="multipart/form-data"> 
            <fieldset>
              <legend class="legend-input">Nama Tamu</legend>
              <input type="text" name="modal-input-pengirim" class="form-control" autocomplete="off" v-model="dataModal.nama_tamu"/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Asal Instansi</legend>
              <input type="text" name="modal-input-penerima" class="form-control" autocomplete="off" v-model="dataModal.asal_instansi"/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Keperluan</legend>
              <textarea name="tujuan" class="form-control" autocomplete="off" v-model="dataModal.keperluan"></textarea>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Nama Driver</legend>
              <input type="text" name="modal-input-resi" class="form-control" autocomplete="off" v-model="dataModal.nama_driver"/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Plat Nomor</legend>
              <input type="text" name="no_polisi" class="form-control" autocomplete="off" v-model="dataModal.no_polisi"/>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Foto Tamu</legend>
              <a class="btn btn-daftar" href="" style="width: 100% !important;">
                  <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
                  Ambil Gambar
                  <input id="file" name="file" type='file' class="attach" accept="image/*" capture @change="onFileChange"/>
              </a>
              <img v-if="imgUploaded" :src="imgUploaded" style="object-fit: contain;width: 100%;height: 100%;text-align:center;margin-top: 10px;"/>
            </fieldset>
            <div class="mb-1">
              <legend class="legend-input mt-3">Jenis Kendaraan</legend>
              <div class="d-flex mt-2">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="status" value="2" v-model="dataModal.jenis_kendaraan"/>
                  <label class="form-check-label" for="status">Roda 2</label>
                </div>
                <div class="form-check ml-4">
                  <input class="form-check-input" type="radio" name="status" value="4" v-model="dataModal.jenis_kendaraan"/>
                  <label class="form-check-label" for="exampleRadios2">Roda 4</label>
                </div>
              </div>
            </div>
            <fieldset>
              <img style="right: 2rem;position: absolute;" src="../../assets/img/arrow-down.png"/>
              <legend class="legend-input">Warna Kartu</legend>
              <select class="form-control" v-model="dataModal.warna_kartu">
                  <option value="">-- Pilih --</option>
                  <option value="biru">BIRU</option>
                  <option value="merah">MERAH</option>
                  <option value="pink">PINK</option>
                  <option value="kuning">KUNING</option>
              </select>
            </fieldset>
            <fieldset>
              <legend class="legend-input">Nomor Kartu</legend>
              <input class="form-control" autocomplete="off" v-model="dataModal.nomor_kartu"/>
            </fieldset>
            <div class="d-flex">
              <fieldset class="mt-2" style="width:50%">
                <legend class="legend-input">Jam Masuk</legend> 
                <!-- <vue-clock-picker v-model="dataModal.v_jam_masuk" class="form-control" style="border:0 !important"></vue-clock-picker> -->
                <input type="time" class="form-control" autocomplete="off" v-model="dataModal.v_jam_masuk"/>
                <!-- <img style="position: absolute;left:155px;top: 425px;width: 25px;" src="../../assets/img/clock.png"> -->
              </fieldset>
              <span style="width:20px;"></span>        
              <fieldset class="mt-2" style="width:50%">
                <legend class="legend-input">Jam Keluar</legend> 
                <!-- <vue-clock-picker v-model="dataModal.v_jam_keluar" class="form-control" style="border:0 !important"></vue-clock-picker> -->
                <input type="time" class="form-control" autocomplete="off" v-model="dataModal.v_jam_keluar"/>
                <!-- <img style="position: absolute;right: 30px;top: 425px;width: 25px;" src="../../assets/img/clock.png"> -->
              </fieldset>
            </div>
            
            <button class="btn-blue mt-4" style="width:100%" :class="{'disable': isLoadingUpload}"  type="submit">
              <span class="d-flex align-items-center justify-content-center" v-if="isLoadingUpload">
                  <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                Sedang Upload...
              </span>
              <span v-else>Simpan</span>
            </button> 
            <span class="btn btn-danger mt-3" style="width:100%" @click="modalDelete(dataModal.id)" :class="{'disable': isSubmit}" type="submit">Hapus</span> 
          </form>
        </template>
      </modal>
      
      <alert ref="c"/>
    </div>
    <div class="d-flex">
      <router-link class="float" to="/lembur/add">
        <i class="fa fa-plus my-float"></i>
      </router-link>
      <span class="float" @click="load()" style="background-color:#e0e0e0;color:#000;right:90px !important">
        <i class="fa fa-refresh my-float"></i>
      </span>
    </div>
  </div>
</template>
 
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css"; 
// import $ from "jquery";
// import _ from "lodash";
import modal from "../../components/Modal.vue";
import Api from "../../Api";
import alertError from "../../components/AlertError.vue";
import alert from "../../components/Alert.vue";

export default {
  name: "Tamu",
  components: {
    DatePicker,
    modal, 
    alert,
    alertError,
  },
  data() {
    var currentdate = new Date(); 
    var datetime = currentdate.getFullYear() + "-"
            + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "-" 
            + ('0' + (currentdate.getDate())).slice(-2)
    return {
      startDate:datetime,
      isLoading: true,
      datas: [],
      isLoadingUpload:false,
      total: 0,
      dataModal: [],
      isModalVisible: false,
      updatedContent: "",
      token: '',
      myData: '',
      imgUploaded:'',
      file_upload:'',
      isSubmit: false, 
      dataFiltered: 0
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem('token'))
    this.myData = JSON.parse(localStorage.getItem('cred'))
    this.load();
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    // Preview Image
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0]
      // this.uploadActive = true
    },
    async load() {
      this.isLoading = true,
      await Api.get("satpam/tamu/"+this.myData.id_tempat_kerja,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          this.datas = response.data.data; 
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
    },
    async update(id){
      // console.log(id)
      this.isLoadingUpload = true 
      const data = {
        user_id: this.myData.id,
        workplace_id: this.myData.id_tempat_kerja,
        nama_tamu: this.dataModal.nama_tamu,
        nama_driver: this.dataModal.nama_driver,
        keperluan: this.dataModal.keperluan,
        no_polisi: this.dataModal.no_polisi,
        jenis_kendaraan: this.dataModal.jenis_kendaraan,
        jam_keluar: this.dataModal.v_jam_keluar,
        jam_masuk: this.dataModal.v_jam_masuk,
        asal_instansi: this.dataModal.asal_instansi,
        warna_kartu: this.dataModal.warna_kartu,
        nomor_kartu:this.dataModal.nomor_kartu,
        file_upload:this.file_upload
      }
      // console.log(data)
      let formData = new FormData();
      
      for (var key in data) { 
          // console.log(key, data[key]);
          formData.append(key, data[key]);
      }  
      await Api.post("satpam/tamu/update/"+id,formData,{
        headers: {
          Authorization: 'Bearer ' + this.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        console.log(response)
        this.closeModal()
        this.load()
        this.$refs.c.showAlert('Sukses','Berhasil Update Data Tamu')
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
      this.isLoadingUpload = false 
    }, 
    async showModal(data) {
      console.log(data)
      this.dataModal = data; 
      await Api.get("satpam/tamu/show/"+data.id,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then((response) => {
          response.data.data.attachments ? this.imgUploaded = response.data.data.attachments.file_url : this.imgUploaded = ''
          // console.log(response.data.data.attachments)
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ae.showAlert()
        });
      // this.updatedContent = data.pengirim
      this.isModalVisible = true;
    },
    closeModal() {
      this.imgUploaded = ''
      this.isModalVisible = false;
    },
    modalDelete(id){
      this.$modal.show('dialog', {
        title: 'Yakin Hapus Data Tamu ?',
        text: 'Data yang telah dihapus tidak dapat dikembalikan',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
          {
            title: 'Hapus',
            handler: () => {
              Api.delete("satpam/tamu/"+id,{
                headers: {Authorization: 'Bearer ' + this.token}
              })
              .then(() => {
                this.closeModal()
                this.$modal.hide('dialog')
                this.load()
              })
              .catch((error) => {

                console.log(error);
                this.$refs.ae.showAlert()
              });
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    },
    cara(){
      this.$refs.c.showAlert('Panduan','1. <b>Kolom berwarna merah</b> artinya, Jam Keluar belum dilengkapi, untuk melengkapinya, dengan klik kolom tamu yang akan di update, kemudian isi <b>jam keluar</b>,kemudian Simpan<br><br>2. <b>Jika data tidak update,</b> klik tombol <b><i class="fa fa-refresh"></i></b> Sebelah tombol <b><i class="fa fa-plus"></i></b><br>')
    }
  },
  // Untuk Search Paket
  computed: {
    filteredData() {
      var vm = this;
      var startDate = vm.startDate;
      // console.log(this.datas)
      return this.datas.filter(item => {
        var date = item.v_tgl_masuk;
        if (date == startDate) {
          // console.log(item.length)
          return date == startDate;
        }
      });
    }
  }
};
</script> 
<style>
  th,td{
    padding-left:3px;
    padding-right:3px;
  } 
</style>